import { createContext, useState, FC, ReactNode } from "react";
import { main } from "./Themes";
export type RadarContextType = {
  theme: any;
  category: string;
  technology: string;
  mainMenu: string;
  setCategory: (newCategory: string) => void;
  setTechnology: (newTechnology: string) => void;
  setMainMenu: (newMainMenu: string) => void;
  setTheme: (newTheme: any) => void;
};

export const RadarContext = createContext<RadarContextType>({
  theme: main,
  category: "",
  technology: "",
  mainMenu: "",
  setCategory: (name: string) => {},
  setTechnology: (name: string) => {},
  setMainMenu: (name: string) => {},
  setTheme: (name: any) => {},
});

type RadarContextProviderProps = {
  children: ReactNode;
};

export const RadarContextProvider: FC<RadarContextProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = useState(main);
  const [mainMenu, setMainMenu] = useState<string>("tech stack");
  const [category, setCategory] = useState<string>("Languages");
  const [technology, setTechnology] = useState<string>("");
  const initialValue = {
    theme,
    setTheme,
    category,
    technology,
    mainMenu,
    setCategory,
    setTechnology,
    setMainMenu,
  };

  return (
    <RadarContext.Provider value={initialValue}>
      {children}
    </RadarContext.Provider>
  );
};
