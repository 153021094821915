export const CertificationIcon = () => (
  <svg
    className="menu-icon"
    width="40px"
    height="40px"
    viewBox="0 0 25.143 25.143"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="transparent"
      d="M18.313,21.852c0.188,0.03,0.38-0.016,0.534-0.129c0.152-0.11,0.258-0.28,0.286-0.469l0.169-1.123
			c0.034-0.226,0.175-0.418,0.379-0.521l1.017-0.508c0.172-0.087,0.301-0.236,0.359-0.417c0.06-0.183,0.041-0.379-0.045-0.549
			l-0.524-1.013c-0.103-0.198-0.103-0.439,0-0.639l0.524-1.012c0.086-0.17,0.104-0.366,0.045-0.549
			c-0.059-0.182-0.188-0.33-0.359-0.417l-1.017-0.508c-0.204-0.103-0.345-0.295-0.379-0.521l-0.169-1.123
			c-0.028-0.189-0.134-0.358-0.286-0.468c-0.154-0.114-0.346-0.16-0.534-0.129l-1.125,0.188c-0.223,0.037-0.449-0.037-0.609-0.198
			l-0.799-0.812c-0.135-0.135-0.316-0.211-0.508-0.211c-0.189,0-0.374,0.077-0.508,0.211l-0.799,0.812
			c-0.159,0.162-0.386,0.235-0.609,0.198l-1.123-0.185c-0.188-0.031-0.38,0.015-0.535,0.129c-0.153,0.11-0.258,0.279-0.286,0.468
			l-0.168,1.123c-0.035,0.226-0.175,0.418-0.379,0.521l-1.018,0.508c-0.169,0.087-0.3,0.235-0.358,0.417
			c-0.059,0.183-0.042,0.379,0.044,0.549l0.524,1.012c0.104,0.199,0.104,0.44,0,0.639l-0.524,1.013
			c-0.086,0.17-0.103,0.366-0.044,0.549c0.059,0.181,0.188,0.33,0.358,0.417l1.018,0.508c0.204,0.103,0.344,0.295,0.379,0.521
			l0.168,1.123c0.028,0.188,0.133,0.358,0.286,0.469c0.154,0.113,0.346,0.159,0.535,0.129l1.123-0.185
			c0.223-0.039,0.45,0.036,0.609,0.197l0.799,0.81c0.135,0.138,0.318,0.214,0.508,0.214c0.191,0,0.374-0.076,0.508-0.214l0.799-0.81
			c0.16-0.161,0.387-0.236,0.609-0.197L18.313,21.852z M15.271,21.032c-2.39,0-4.333-1.943-4.333-4.332s1.944-4.334,4.333-4.334
			c2.39,0,4.333,1.946,4.333,4.334S17.662,21.032,15.271,21.032z"
    />
    <path
      stroke="transparent"
      d="M15.272,12.991c-2.041,0-3.703,1.66-3.703,3.702s1.663,3.702,3.703,3.702
			c2.043,0,3.703-1.66,3.703-3.702S17.315,12.991,15.272,12.991z"
    />
    <path
      stroke="transparent"
      d="M21.725,22.663l-2.015-2.016l-0.102,0.68c-0.048,0.313-0.222,0.602-0.479,0.787
			c-0.255,0.186-0.574,0.265-0.892,0.213l-1.126-0.184c-0.093-0.006-0.149,0.02-0.19,0.062l-0.192,0.193l2.632,2.631
			c0.102,0.102,0.249,0.138,0.385,0.097c0.136-0.043,0.236-0.156,0.264-0.297l0.237-1.277l1.281-0.24
			c0.14-0.026,0.253-0.127,0.294-0.264C21.864,22.911,21.826,22.762,21.725,22.663z"
    />
    <path
      stroke="transparent"
      d="M13.469,22.138l-1.16,0.189c-0.325,0.05-0.64-0.028-0.896-0.216
			c-0.255-0.184-0.429-0.472-0.477-0.787l-0.102-0.677L8.82,22.663c-0.1,0.1-0.137,0.248-0.096,0.384
			c0.043,0.137,0.157,0.236,0.295,0.264l1.28,0.239l0.24,1.279c0.026,0.141,0.127,0.254,0.263,0.297
			c0.135,0.041,0.283,0.005,0.383-0.096l2.631-2.632l-0.192-0.194C13.582,22.161,13.526,22.138,13.469,22.138z"
    />
    <path
      stroke="transparent"
      d="M16.42,4.217H6.985c-0.34,0-0.615,0.275-0.615,0.615C6.369,5.174,6.644,5.448,6.985,5.448h9.435
			c0.339,0,0.615-0.274,0.615-0.614C17.035,4.494,16.758,4.217,16.42,4.217z"
    />
    <path
      stroke="transparent"
      d="M16.42,7.371H6.985c-0.34,0-0.615,0.275-0.615,0.613C6.369,8.324,6.644,8.6,6.985,8.6h9.435
			c0.339,0,0.615-0.274,0.615-0.615C17.035,7.646,16.758,7.371,16.42,7.371z"
    />
    <path
      stroke="transparent"
      d="M9.872,20.216l-0.465-0.232c-0.119-0.06-0.227-0.137-0.327-0.223H5.108
			c-0.272,0-0.493-0.222-0.493-0.492V1.804c0-0.273,0.221-0.494,0.493-0.494h13.218c0.271,0,0.493,0.221,0.493,0.494v9.007
			c0.22,0.052,0.43,0.148,0.614,0.285c0.36,0.257,0.609,0.668,0.676,1.116l0.02-10.407C20.131,0.81,19.321,0,18.326,0H5.108
			C4.113,0,3.303,0.811,3.303,1.805v17.466c0,0.996,0.811,1.806,1.805,1.806h3.905L9.872,20.216z"
    />
  </svg>
);
