import { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AppBar,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import github from "./img/github.svg";
import linkedin from "./img/linkedin.svg";
import email from "./img/email.png";
import styled from "styled-components/macro";
import MainDrawer from "./MainDrawer";

// import test from './test.jpeg';
// background-image: url(${test});
const StyledAppBar = styled(AppBar)`
  && {
    background: transparent;
    box-shadow: none;
    align-items: center;
    position: fixed;
    @media screen and (max-width: 1000px) {
      position: unset;
    }
    .MuiGrid-container {
      justify-content: center;
    }
    .MuiIconButton-root {
      background: #e6236d;
      border-radius: 0;
    }

    img:hover {
      transition: all 0.5s ease-in-out;
      opacity: 0.6;
    }

    .contact {
      background: ${({ theme }) => theme.contactBackground};
      height: 100vh;
      > div {
        margin: 34px 10px;
        display: flex;
        flex-direction: column;
      }
      @media screen and (max-width: 1000px) {
        display: none !important;
      }
    }
    .mobile-menu {
      @media screen and (min-width: 1000px) {
        display: none !important;
      }
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  div.MuiPaper-root {
    width: 100%;
    background: #5d5fff;
    color: white;
    overflow-x: hidden;
  }
  .MuiIconButton-root {
    margin: 20px;
    float: right;
  }
  ul {
    height: ${window.innerHeight}px;
    width: 100%;
    padding: 150px 0 0 80px;

    a {
      font-size: 30px;
      font-weight: bold;
      color: inherit;
      font-family: "Bebas Neue", cursive;
      :hover {
        text-decoration: underline;
        background: transparent;
      }
    }
    @media screen and (max-width: 1000px) {
      padding: 100px 0 0 20px;
      .MuiListItem-root {
        padding-bottom: 0;
      }
    }
  }
`;

const menuItems = [
  { name: "Tech Stack", url: "" },
  { name: "Experience", url: "experience" },
  { name: "Education", url: "education" },
  // { name: "Creative", url: "creative" },
  { name: "Certifications", url: "certifications" },
];

const Menu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <>
      <StyledAppBar position="static">
        <Grid container style={{ justifyContent: "space-between" }}>
          <div />
          <Grid item className="mobile-menu">
            <IconButton
              onClick={toggleDrawer(true)}
              onKeyDown={toggleDrawer(true)}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item className="contact">
            <div>
              <a
                href="https://github.com/xandrav"
                target="_blank"
                rel="noreferrer"
                style={{ marginBottom: 10 }}
              >
                <img src={github} width={30} height={30} alt='github' />
              </a>
              <a
                href="https://www.linkedin.com/in/alexandra-veres95/"
                target="_blank"
                rel="noreferrer"
                style={{ marginBottom: 10 }}
              >
                <img src={linkedin} width={30} height={30} alt='linkedin'/>
              </a>
              <a href="mailto: veresalexandra95@gmail.com">
                <img src={email} width={27} height={27} alt='email' />
              </a>
            </div>
          </Grid>
        </Grid>
      </StyledAppBar>

      <MainDrawer />

      <StyledDrawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <IconButton
            onClick={toggleDrawer(false)}
            edge="start"
            color="inherit"
            aria-label="clear"
          >
            <ClearIcon fontSize="large" />
          </IconButton>
          <List>
            {menuItems.map(({ name, url }) => (
              <ListItem key={`menu-${name}`} component={Link} to={`/${url}`}>
                {name}
              </ListItem>
            ))}
          </List>
        </div>
      </StyledDrawer>
    </>
  );
};

export default Menu;
