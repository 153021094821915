export const RobotIcon = () => (
  <svg
    className="menu-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 70 70"
  >
    <g>
      <path
        stroke="transparent"
        d="M51.0004 40.7286H17.0004C14.783 40.7286 12.812 39.0112 12.812 36.5576V15.4573C12.812 13.2491 14.5367 11.2863 17.0004 11.2863H50.754C52.9714 11.2863 54.9424 13.0037 54.9424 15.4573V36.803C55.1888 38.7658 53.2178 40.7286 51.0004 40.7286ZM17.0004 13.9851C16.2613 13.9851 15.7685 14.7212 15.7685 15.4573V36.803C15.7685 37.539 16.2613 38.2751 17.2468 38.2751H51.0004C51.7396 38.2751 52.4787 37.7844 52.4787 36.803V15.4573C52.4787 14.7212 51.9859 13.9851 51.0004 13.9851H17.0004Z"
      />
      <path
        stroke="transparent"
        d="M59.1305 33.368H53.7102C52.9711 33.368 52.2319 32.8773 52.2319 31.8959V19.6282C52.2319 18.8922 52.7247 18.1561 53.7102 18.1561H59.1305C61.3479 18.1561 63.3189 19.8736 63.3189 22.3271V29.4424C63.3189 31.6505 61.5942 33.368 59.1305 33.368ZM55.1885 30.6691H59.1305C59.8696 30.6691 60.6087 30.1784 60.6087 29.197V22.5725C60.6087 21.8364 60.116 21.1004 59.1305 21.1004H54.9421V30.6691H55.1885Z"
      />
      <path
        stroke="transparent"
        d="M62.0871 24.2899C61.348 24.2899 60.6089 23.7992 60.6089 22.8178V10.5501C60.6089 9.81406 61.1016 9.078 62.0871 9.078C63.0727 9.078 63.5654 9.56871 63.5654 10.5501V22.8178C63.319 23.7992 62.8263 24.2899 62.0871 24.2899Z"
      />
      <path
        stroke="transparent"
        d="M14.2898 33.368H8.86956C6.65217 33.368 4.68115 31.6506 4.68115 29.197V22.5725C4.68115 20.3643 6.40579 18.4015 8.86956 18.4015H14.2898C15.029 18.4015 15.7681 18.8922 15.7681 19.8736V32.1413C15.7681 32.8773 15.029 33.368 14.2898 33.368ZM8.86956 21.3457C8.13043 21.3457 7.3913 21.8364 7.3913 22.5725V29.4424C7.3913 30.1784 7.88405 30.9145 8.86956 30.9145H13.058V21.3457H8.86956Z"
      />
      <path
        stroke="transparent"
        d="M6.15941 24.2899C5.42028 24.2899 4.68115 23.7992 4.68115 22.8178V10.5501C4.68115 9.81406 5.17391 9.078 6.15941 9.078C6.89854 9.56871 7.3913 10.0594 7.3913 10.7955V23.0631C7.3913 23.7992 6.89854 24.2899 6.15941 24.2899Z"
      />
      <path
        stroke="transparent"
        d="M6.15942 12.2677C2.71015 12.2677 0 9.56877 0 6.13383C0 2.69888 2.71015 0 6.15942 0C9.6087 0 12.3188 2.69888 12.3188 6.13383C12.3188 9.56877 9.6087 12.2677 6.15942 12.2677ZM6.15942 2.69888C4.18841 2.69888 2.71015 4.171 2.71015 6.13383C2.71015 8.09665 4.18841 9.56877 6.15942 9.56877C8.13043 9.56877 9.6087 8.09665 9.6087 6.13383C9.6087 4.171 7.88406 2.69888 6.15942 2.69888Z"
      />
      <path
        stroke="transparent"
        d="M62.0872 12.2677C58.6379 12.2677 55.9277 9.56877 55.9277 6.13383C55.9277 2.69888 58.6379 0 62.0872 0C65.5364 0 68.2466 2.69888 68.2466 6.13383C68.2466 9.56877 65.2901 12.2677 62.0872 12.2677ZM62.0872 2.69888C60.1161 2.69888 58.6379 4.171 58.6379 6.13383C58.6379 8.09665 60.1161 9.56877 62.0872 9.56877C64.0582 9.56877 65.5364 8.09665 65.5364 6.13383C65.2901 4.171 63.8118 2.69888 62.0872 2.69888Z"
      />
      <path
        stroke="transparent"
        d="M25.6228 28.461C22.9127 28.461 20.6953 26.2528 20.6953 23.5539C20.6953 20.855 22.9127 18.6469 25.6228 18.6469C28.333 18.6469 30.5504 20.855 30.5504 23.5539C30.5504 26.2528 28.333 28.461 25.6228 28.461ZM25.6228 21.3457C24.391 21.3457 23.4055 22.3272 23.4055 23.5539C23.4055 24.7807 24.391 25.7621 25.6228 25.7621C26.8547 25.7621 27.8402 24.7807 27.8402 23.5539C27.8402 22.3272 26.8547 21.3457 25.6228 21.3457Z"
      />
      <path
        stroke="transparent"
        d="M42.3763 28.461C39.6661 28.461 37.4487 26.2528 37.4487 23.5539C37.4487 20.855 39.6661 18.6469 42.3763 18.6469C45.0864 18.6469 47.3038 20.855 47.3038 23.5539C47.3038 26.2528 45.0864 28.461 42.3763 28.461ZM42.3763 21.3457C41.1444 21.3457 40.1589 22.3272 40.1589 23.5539C40.1589 24.7807 41.1444 25.7621 42.3763 25.7621C43.6082 25.7621 44.5937 24.7807 44.5937 23.5539C44.5937 22.3272 43.6082 21.3457 42.3763 21.3457Z"
      />
      <path
        stroke="transparent"
        d="M33.9996 35.5762C32.0286 35.5762 30.0576 34.5947 28.8257 32.8773C28.333 32.3866 28.5793 31.4052 29.0721 30.9144C29.5649 30.4237 30.5504 30.6691 31.0431 31.1598C31.7822 32.1412 32.7678 32.6319 33.9996 32.6319C35.2315 32.6319 36.217 32.1412 36.9562 31.1598C37.4489 30.6691 38.188 30.4237 38.9272 30.9144C39.6663 31.4052 39.6663 32.1412 39.1735 32.8773C38.188 34.5947 36.217 35.5762 33.9996 35.5762Z"
      />
      <path
        stroke="transparent"
        d="M51.0004 65.9999H17.0004C14.783 65.9999 12.812 64.2825 12.812 61.8289V49.8066C12.812 47.5984 14.5367 45.6356 17.0004 45.6356H50.754C52.9714 45.6356 54.9424 47.3531 54.9424 49.8066V61.8289C55.1888 64.0371 53.2178 65.9999 51.0004 65.9999ZM17.0004 48.5799C16.2613 48.5799 15.5222 49.0706 15.5222 50.052V62.0743C15.5222 62.8103 16.0149 63.5464 17.0004 63.5464H50.754C51.4932 63.5464 52.2323 63.0557 52.2323 62.0743V50.052C52.2323 49.3159 51.7396 48.5799 50.754 48.5799H17.0004Z"
      />
      <path
        stroke="transparent"
        d="M34.0002 48.58C33.2611 48.58 32.522 48.0893 32.522 47.1078V39.2565C32.522 38.5205 33.0147 37.7844 34.0002 37.7844C34.7394 37.7844 35.4785 38.2751 35.4785 39.2565V47.1078C35.4785 47.8439 34.7394 48.58 34.0002 48.58Z"
      />
    </g>
  </svg>
);
