import styled from "styled-components/macro";

const StyledFooter = styled.div`
  height: 40px;
  text-align: center;
  font-size: 12px;
  font-family: poppins, sans-serif;
  color: white;

  bottom: 0;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  a,
  div {
    text-decoration: none;
    color: inherit;
    margin-right: 60px;
    transition: all 0.3s ease;
  }
  a:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  div {
    float: right;
  }
  img {
    display: none;
  }
  @media screen and (min-width: 1000px) {
    a {
      display: none;
    }
  }
  @media screen and (max-width: 1000px) {
    height: 100px;
    font-size: 16px;
    position: relative;
    a,
    div {
      float: none;
    }
    img {
      display: block;
      margin: auto;
    }
  }
`;

const Footer = () => (
  <StyledFooter className="footer">
    <a href="https://github.com/xandrav" target="_blank" rel="noreferrer">
      GitHub{" |"}
    </a>
    <a
      href="https://www.linkedin.com/in/alexandra-veres95/"
      target="_blank"
      rel="noreferrer"
    >
      &nbsp;LinkedIn{" |"}
    </a>
    <a href="mailto: veresalexandra95@gmail.com">&nbsp;Email</a>
    <div>©2024 Alexandra Veres</div>
  </StyledFooter>
);

export default Footer;
