// export const main = {
//   name: "main",
//   profileText: "#123693",
//   text: "white",
//   background: "#5D5FFF",
//   navBackground: "#ffc864",
//   sideBarText: "white",
//   contactBackground: "transparent",
//   pcodeColor: "white",
//   numberColor: "#5D5FFF"
// };
export const main = {
  name: "main0",
  profileText: "white",
  text: "white",
  background: "linear-gradient(-45deg, #5d5fff, #ff98b6, #00bfff)", //linear-gradient(-45deg, #5d5fff, #ff98b6, #ffc200)
  // "radial-gradient(81% 63% at 0% 60%, #620027 0%,rgb(230 35 109) 100%),linear-gradient(223deg, #ce4267 13.85%, rgb(85 58 170) 78.35%),radial-gradient(50% 50% at 50% 50%, #42006a 0%, rgb(133 13 255 / 96%) 100%)",
  navBackground: "rgb(35 55 230 / 35%);",
  //"#ffc864",
  sideBarText: "white",
  contactBackground: "transparent",
  pcodeColor: "white",
  numberColor: "#e6236d",
};

export const main2 = {
  name: "main2",
  profileText: "white",
  text: "white",
  background: "linear-gradient(-45deg, #e6236d, #ff98b6, #ffc864);",
  navBackground: "rgb(255 255 255 / 20%);",
  sideBarText: "white",
  contactBackground: "transparent",
  pcodeColor: "white",
  numberColor: "#e6236d",
};
export const mai3 = {
  name: "main3",
  profileText: "white",
  text: "white",
  background: "linear-gradient(-45deg, #e6236d, #b598ff, #ff98b6);",
  navBackground: "rgb(255 255 255 / 20%);",
  sideBarText: "white",
  contactBackground: "transparent",
  pcodeColor: "white",
  numberColor: "#e6236d",
};

export const experience = {
  name: "experience",
  profileText: "#123693",
  text: "#123693",
  background: "#E7ECF9",
  navBackground: "#fcbcdb",
  sideBarText: "#123693",
  contactBackground: "#fcbcdb",
  hoverListItemBackground: "#d5ffed",
  pcodeColor: "#ff01af",
};

export const education = {
  name: "experience",
  profileText: "#123693",
  text: "black",
  background: "#fffae5",
  navBackground: "#ffc864",
  sideBarText: "black",
  contactBackground: "#ffd5d5",
  hoverListItemBackground: "#ffeacc",
  pcodeColor: "white",
};

export const certifications = {
  name: "certifications",
  profileText: "#123693",
  text: "black",
  background: "#fffae5",
  navBackground: "#ffc864",
  sideBarText: "black",
  contactBackground: "#ffd5d5",
  hoverListItemBackground: "#ffeacc",
  pcodeColor: "white",
};

export const creative = {
  name: "creative",
  profileText: "white",
  text: "white",
  background: "linear-gradient(-45deg, #5d5fff, #ff98b6, #ffc200)",
  navBackground: "rgb(255 93 93 / 31%)",
  sideBarText: "white",
  contactBackground: "transparent",
  pcodeColor: "white",
};
