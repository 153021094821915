import styled from "styled-components/macro";

const Wrapper = styled.div`
  padding: 30px 40px;
  background: transparent;

  .pcode {
    font-family: Reenie Beanie;
    font-size: 30px;
    font-weight: 500;
    color: ${({ theme }) => theme.pcodeColor};
    text-align: left;
  }
`;
const StyledLogo = styled.div`
  padding: 0px 0px 0px 30px;
  text-align: left;
  display: inline-flex;
  font-family: Georgia, Times, Times New Roman, serif;
  color: ${({ theme }) => theme.profileText};
  div:first-child {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 6px;
  }
  div:last-child {
    font-size: 14px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0px 0px 0px 0px;
  }
`;

const Logo = () => {
  return (
    <Wrapper>
      <div className="pcode">{"<h3>"}</div>
      <StyledLogo>
        <img
          style={{
            borderRadius: 100,
            border: "2px solid white",
            marginRight: 10,
          }}
          className="profile"
          src={`${process.env.REACT_APP_S3_BASE_URL}linkedinpic.jpeg`}
          alt={"profile"}
          height={50}
        />
        <span style={{ fontWeight: "bold", marginTop: 5 }}>
          Alexandra Veres<div>frontend engineer</div>
        </span>
      </StyledLogo>
      <div className="pcode">{"</h3>"}</div>
    </Wrapper>
  );
};

const StyledMobileLogo = styled.div`
  display: none !important;
  @media screen and (max-width: 1000px) {
    display: block !important;
    margin-top: -50px;
    background: transparent !important;
   
  }
`;

export const MobileLogo = () => (
  <StyledMobileLogo>
    <Logo />
  </StyledMobileLogo>
);

export default Logo;
