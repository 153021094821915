import { useContext, useEffect, useState } from "react";
import { Collapse, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { RadarContext, RadarContextType } from "./RadarContextProvider";
import { menuItems, techStackMenu } from "./data/data";
import styled from "styled-components/macro";

const StyledSideBar = styled.div`
  margin: 0px 0 0px 30px;
  float: left;
  position: fixed;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  .MuiList-padding {
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    display: none;
    position: unset;
    width: calc(100% + 50px);
  }
`;

const StyledList = styled(List)`
  && {
    width: 280px;
    text-align: left;
    font-family: "Bebas Neue", cursive;
    vertical-align: top;
    font-size: 20px;
    margin-top: 5px;
    margin-left: 20px;
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.sideBarText};
      :hover {
        opacity: 0.7;
      }
    }
    .nested {
      margin-left: 15px;
      li.tech.list-item:last-child {
        margin-bottom: 10px;
      }
    }
    .number {
      margin-right: 10px;
      color: ${({ theme }) => theme.numberColor};
    }
    .symbol {
      margin-right: 10px;
      color: ${({ theme }) => theme.sideBarText};
    }
    .list-item {
      cursor: pointer;
      height: 26px;
      color: ${({ theme }) => theme.sideBarText};
    }
    .category.list-item {
      font-size: 16px;
    }
    .tech.list-item {
      font-size: 14px;
      font-family: poppins, sans-serif;
    }
    .MuiListItem-root.Mui-disabled {
      opacity: 1;
    }
    .sub-category {
      font-size: 16px;
      font-family: "Bebas Neue", cursive;
      margin-top: 10px;
    }
    .hovered {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;
type SideBarProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SideBar: React.FC<SideBarProps> = ({ setOpen }) => {
  const {
    mainMenu,
    category,
    technology,
    setMainMenu,
    setCategory,
    setTechnology,
  } = useContext<RadarContextType>(RadarContext);
  const [techStackIsOpen, setTechStackIsOpen] = useState(true);

  useEffect(() => {
    if (window.location.pathname !== "/")
      setMainMenu(window.location.pathname.split("/")[1].replace(/-/g, " "));
  });

  return (
    <>
      <StyledSideBar>
        <StyledList className="main-menu" key={techStackMenu.name}>
          <Link
            to={`/`}
            onClick={() => {
              setTechStackIsOpen(!techStackIsOpen);
              setMainMenu(techStackMenu.name.toLowerCase());
            }}
          >
            {techStackMenu.name}
          </Link>
          <Collapse
            in={
              techStackIsOpen && mainMenu === techStackMenu.name.toLowerCase()
            }
            timeout="auto"
            unmountOnExit
          >
            <List component="nav" className={"nested"}>
              {techStackMenu.subMenu.map(({ subName, items }, idx: any) => (
                <span key={subName}>
                  <ListItem
                    className={`category list-item`}
                    onClick={() => setCategory(subName)}
                  >
                    <span className="number">0{idx + 1}</span>
                    {subName}
                  </ListItem>
                  <Collapse
                    in={subName === category}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem className={"nested list-item sub-category"}>
                        preferred
                      </ListItem>
                      {items.preferred.map((tech: string) => (
                        <ListItem
                          key={tech}
                          className={`nested tech list-item ${
                            technology === tech ? "hovered" : ""
                          }`}
                          onMouseOver={() => setTechnology(tech)}
                          onMouseLeave={() => setTechnology("")}
                        >
                          {tech}
                        </ListItem>
                      ))}
                      <ListItem className={"nested list-item sub-category"}>
                        learning
                      </ListItem>
                      {items.learning.map((tech: string) => (
                        <ListItem
                          key={tech}
                          className={`nested tech list-item ${
                            technology === tech ? "hovered" : ""
                          }`}
                          onMouseOver={() => setTechnology(tech)}
                          onMouseLeave={() => setTechnology("")}
                        >
                          {tech}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </span>
              ))}
            </List>
          </Collapse>
        </StyledList>

        {menuItems.map(({ name, url, subMenu }: any) => (
          <StyledList className="main-menu" key={name}>
            <Link
              to={`/${url}`}
              onClick={() => {
                setTechStackIsOpen(false);
                setMainMenu(url.toLowerCase());
                setOpen(false);
              }}
            >
              {name}
            </Link>
            <Collapse
              in={url.toLowerCase() === mainMenu}
              timeout="auto"
              unmountOnExit
            >
              <List component="nav" className={""}>
                {subMenu.map((item: string) => (
                  <span key={item}>
                    <ListItem
                      className={`category list-item`}
                      onClick={() => setCategory(item)}
                    >
                      <span className="symbol">{"⟡"}</span>

                      {item}
                    </ListItem>
                  </span>
                ))}
              </List>
            </Collapse>
          </StyledList>
        ))}
      </StyledSideBar>
    </>
  );
};

export default SideBar;
