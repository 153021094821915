import { FC } from "react";
import { ExperienceContent } from "./Content";
import styled from "styled-components/macro";
const StyledContent = styled.div`
  font-family: poppins, sans-serif;
  text-align: left;
  width: 80w;
  max-width: 800px;
  margin-top: 100px;
  display: inline-block;
  @keyframes yoyo {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(30px);
      transform: translateX(30px);
    }
  }
  .test:hover {
    animation-duration: 0.8s;
    animation-name: yoyo;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-top: 0px;
    img.background {
      display: none;
    }
    .MuiSvgIcon-root {
      display: none;
    }
  }
  @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  animation-delay: 0.3s;

  .visit-job {
    padding: 40px 40px 0px 40px;
  }
  .visit-job:hover {
    background-color: ${({ theme }) => theme.hoverListItemBackground};
    border-radius: 5px;
    transition: all 0.4s ease;
    .MuiSvgIcon-root {
      transform: translate(5px, -5px);
      transition: all 0.5s ease-in-out;
    }

    @media screen and (max-width: 1000px) {
      background-color: rgb(255 255 255 / 0%);
    }
  }
  .job-link {
    color: inherit;
    text-decoration: inherit;
  }
`;

interface ExperienceType {
  jobs: Array<{
    title: string;
    companyName: string;
    description: string;
    date?: string;
    img: string;
    url: string;
    tech?: string[];
  }>;
  title: string;
}

const ExperiencePage: FC<ExperienceType> = ({ jobs }) =>
  jobs && (
    <StyledContent>
      {jobs.map((job, idx) => (
        <div key={`${job.companyName}-${idx}`} className="test">
          <div className="visit-job test">
            <a
              className="job-link"
              target="_blank"
              rel="noreferrer"
              href={job.url}
            >
              <ExperienceContent contentData={job} />
            </a>
          </div>
          <hr />
        </div>
      ))}
    </StyledContent>
  );

export default ExperiencePage;
