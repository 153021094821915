import { FC, ReactNode } from "react";
import styled from "styled-components/macro";

const StyledPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.background};
  // background: radial-gradient(
  //     81% 63% at 0% 60%,
  //     #e6236d 0%,
  //     rgba(230, 35, 109, 0) 100%
  //   ),
  //   linear-gradient(223deg, #6342ce 13.85%, rgba(85, 58, 170, 0) 78.35%),
  //   radial-gradient(
  //     50% 50% at 50% 50%,
  //     #ffffff38 0%,
  //     rgba(255, 255, 255, 0) 100%
  //   );
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  .background {
    color: white;
    opacity: 0.12;
    position: absolute;
    top: 110px;
    left: -30px;
  }
  img {
    margin: auto;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 40%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  text-align: center;
  color: white;
  overflow-x: hidden;
  a:hover {
    text-decoration: none;
  }
  hr {
    margin: 20px 0px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255),
      rgba(255, 255, 255, 0)
    );
  }
  @media screen and (max-width: 1000px) {
    .company,
    .certification {
      display: none;
    }
  }
`;

type BoxProps = {
  children: ReactNode;
};

const PageWrapper: FC<BoxProps> = ({ children }) => {
  return (
    <StyledPageWrapper>
      {/* <img
        className="background"
        src={background}
        alt={"background"}
        width={350}
        height={350}
      /> */}

      {children}
    </StyledPageWrapper>
  );
};

export default PageWrapper;
