export const ExperienceIcon = () => (
  <svg
    className="menu-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="35px"
    viewBox="0 0 32 32"
  >
    <path
      stroke="transparent"
      d="M28 8.25h-4.25v-3.25c-0.002-1.518-1.232-2.748-2.75-2.75h-10c-1.518 0.002-2.748 1.232-2.75 2.75v3.25h-4.25c-1.518 0.002-2.748 1.232-2.75 2.75v16c0.002 1.518 1.232 2.748 2.75 2.75h24c1.518-0.002 2.748-1.232 2.75-2.75v-16c-0.002-1.518-1.232-2.748-2.75-2.75h-0zM9.75 5c0.001-0.69 0.56-1.249 1.25-1.25h10c0.69 0.001 1.249 0.56 1.25 1.25v3.25h-12.5zM4 9.75h24c0.69 0.001 1.249 0.56 1.25 1.25v4.25h-26.5v-4.25c0.001-0.69 0.56-1.249 1.25-1.25h0zM13.75 16.75h4.5v0.75c-0.006 0.412-0.338 0.744-0.749 0.75h-3.001c-0.414-0.001-0.749-0.336-0.75-0.75v-0zM28 28.25h-24c-0.69-0.001-1.249-0.56-1.25-1.25v-10.25h9.5v0.75c0.002 1.242 1.008 2.248 2.25 2.25h3c1.242-0.002 2.248-1.008 2.25-2.25v-0.75h9.5v10.25c-0.001 0.69-0.56 1.249-1.25 1.25h-0z"
    ></path>
  </svg>
);
