import { useState, useContext } from "react";
import { RadarContext, RadarContextType } from "./RadarContextProvider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styled from "styled-components/macro";
import SideBar from "./SideBar";
import Logo, { MobileLogo } from "./Logo";
import Tooltip from "@mui/material/Tooltip";
import { iconMenuItems } from "./data/data";
import { Link } from "react-router-dom";
import { EducationIcon } from "./img/EducationIcon";
import { CertificationIcon } from "./img/CertificationIcon";
import { RobotIcon } from "./img/RobotIcon";
import { ExperienceIcon } from "./img/ExperienceIcon";

const drawerWidth = 310;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

type DrawerProps = {
  open: boolean;
};

const Drawer = styled(MuiDrawer)<DrawerProps>`
  && {
    background: ${({ theme }) => theme.navBackground};
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    overflow-x: hidden;
    transition-property: width;
    .MuiDrawer-paper {
      transition: width 0.5s;
      border: none;
      background: ${({ theme }) => theme.navBackground};
      width: ${({ open }) => (open ? drawerWidth : 70)}px;
    }
    a {
      color: ${({ theme }) => theme.sideBarText};
    }
    svg.menu-icon {
      fill: ${({ theme }) => theme.sideBarText} !important;

      stroke: ${({ theme }) => theme.sideBarText} !important;
      path {
        fill: ${({ theme }) => theme.sideBarText} !important;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const renderMenuIcon = (name: string) => {
  switch (name) {
    case "Education":
      return <EducationIcon />;
    case "Certifications":
      return <CertificationIcon />;
    case "Experience":
      return <ExperienceIcon />;
    default:
      return <RobotIcon />;
  }
};

export default function MiniDrawer() {
  const [open, setOpen] = useState(true);
  const { setMainMenu } = useContext<RadarContextType>(RadarContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MobileLogo />
      <Drawer anchor={"left"} variant="permanent" open={open}>
        <DrawerHeader>
          {open && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </DrawerHeader>
        {open && (
          <div style={{ marginTop: -60 }}>
            <Logo />
          </div>
        )}

        <List>
          <ListItem
            key={"pic"}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!open && (
              <img
                style={{
                  borderRadius: 100,
                  border: "2px solid white",
                }}
                src={`${process.env.REACT_APP_S3_BASE_URL}linkedinpic.jpeg`}
                alt={"profile"}
                height={45}
              />
            )}
          </ListItem>
          <ListItem
            key={"menu"}
            disablePadding
            sx={{
              display: "contents",
              justifyContent: "center",
            }}
          >
            {!open && (
              <IconButton
                style={{
                  borderRadius: 50,
                  background: "#ffffff5e",
                  marginBottom: 30,
                }}
                onClick={handleDrawerOpen}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </ListItem>
          {!open &&
            iconMenuItems.map(({ name, url }: any) => (
              <ListItem key={name} disablePadding sx={{ display: "block" }}>
                <Link
                  to={`/${url}`}
                  onClick={() => {
                    return setMainMenu(name.toLowerCase());
                  }}
                >
                  <Tooltip title={name} placement="right">
                    <ListItemButton
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      {renderMenuIcon(name)}
                    </ListItemButton>
                  </Tooltip>
                </Link>
              </ListItem>
            ))}
          {open && <SideBar setOpen={setOpen} />}
        </List>
      </Drawer>
    </>
  );
}
