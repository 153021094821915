export const creativeData = [
  {
    name: "Mineral Databse",
    companyName: "",
    description:
      " A React application that allows the user to search for minerals by selecting elements in the periodic table, view the info page of a chosen mineral and a dashboard with statistics tailored to the specific mineral properties. Data used in this application was collected from <a style='display:inline-block' href='https://www.mindat.org'>mindat</a> using Python Request library.",
    url: "https://master.d3psdhyvaqiqz2.amplifyapp.com/",
    github: "https://github.com/XandraV/mineral-database",
  },
];

export const certificationsData = [
  {
    title: "AWS Certified Cloud Practitioner 2022",
    companyName: "Amazon Web Services",
    description:
      "Learnt the fundamentals of IT services and their uses in the AWS Cloud, demonstrated the ability to identify essential AWS services necessary to set up AWS-focused projects.",
    url: "https://www.credly.com/badges/093b83b1-6014-4321-8692-1c0c6ef5dafc/",
    img: "https://images.credly.com/size/680x680/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png",
  },
  {
    title: "Qiskit Global Summer School 2022 - Quantum Excellence",
    companyName: "IBM",
    description:
      "Explored the world of quantum computing and its applications with a focus on quantum simulations using NISQ hardware.",
    url: "https://github.com/XandraV/quantum-computing-summer-school",
    img: "https://images.credly.com/size/680x680/images/36d21fa5-2f19-4cdc-a80d-29488161b6cd/image.png",
  },
  {
    title: "IBM Machine Learning Professional Certificate",
    companyName: "Coursera",
    description:
      "This <a style='display:inline-block' href='https://www.coursera.org/professional-certificates/ibm-machine-learning'>program</a> consists of 6 courses to develop working skills in Supervised Learning, Unsupervised Learning, Deep Learning, Reinforcement Learning and specialized topics such as Time Series Analysis and Survival Analysis.",
    url: "https://github.com/XandraV/ibm-machine-learning-certificate-projects",
    img: `${process.env.REACT_APP_S3_BASE_URL}ibm.png`,
  },
  {
    title: "Introduction to Machine Learning with TensorFlow",
    companyName: "Udacity",
    description:
      "This <a style='display:inline-block' href='https://www.udacity.com/course/intro-to-machine-learning-with-tensorflow-nanodegree--nd230'>nanodegree</a> program by Udacity consists of 3 courses - Supervised Learning, Deep Learning, Unsupervised Learning - to learn foundational machine learning algorithms, data cleaning, supervised, deep and unsupervised learning.",
    url: "https://github.com/XandraV/machine-learning-nanodegree",
    img: `${process.env.REACT_APP_S3_BASE_URL}udacity1.png`,
  },
  {
    title: "Deep Reinforcement Learning Nanodegree",
    companyName: "Udacity",
    description:
      "This <a style='display:inline-block' href='https://www.udacity.com/course/deep-reinforcement-learning-nanodegree--nd893'>nanodegree</a> program by Udacity consists of 4 courses - Foundations of Reinforcement Learning, Value-Based Methods, Policy-Based Methods, Multi-Agent Reinforcement Learning - to learn cutting-edge deep reinforcement learning algorithms from Deep Q-Networks (DQN) to Deep Deterministic Policy Gradients (DDPG) and apply these concepts to train agents to walk, drive, or perform other complex tasks.",

    url: "https://github.com/XandraV/deep-reinforcement-learning-nanodegree",
    img: `${process.env.REACT_APP_S3_BASE_URL}udacity2.png`,
  },
];

export const experienceData = [
  {
    title: "Senior Frontend Engineer",
    companyName: "J.P. Morgan Chase & Co.",
    description:
      "<p style='font-size:15px;'>Commercial & Investment Bank</p><ul><li>Developed React, TypeScript components for the J.P. Morgan Markets eXecute Platform, enabling thousands of users to perform electronic trading across various financial products</li><li>Worked on the real-time communication between the UI and microservices using RxJS to manage incoming data streams from Solace topics</li></ul><p style='font-size:15px;'>Asset Management</p><ul><li>Developing the UI for a system that supports the full Investment Management cycle with the main focus on portfolio construction, implementation and optimization. </li><li>Building reusable components using React, Redux, TypeScript, ag-Grid and unit tests in Jest</li></ul>",
    date: "March 2022 - present",
    url: "https://www.jpmorganchase.com/",
    img: "https://www.blackindata.co.uk/wp-content/uploads/2023/07/210909_jp-morgan_logo.jpg",
    tech: [
      "TypeScript",
      "React",
      "Redux",
      "Cypress",
      "RxJS",
      "XState",
      "Vite",
      "Nx",
      "Jenkins",
      "Playwright",
      "styled-components",
      "Ag-Grid",
      "Figma",
    ],
  },
  {
    title: "Frontend Engineer (Consultant)",
    companyName: "Evelyn Partners via Capco",
    description:
      "<ul><li>Developed payments features for a wealth management and investment platform for 35K+ users using React, Redux, TypeScript, D3.js, MaterialUI</li><li>Collaborated with UX/UI designers, Technical Analysts and implemented Agile practices</li></ul>",
    date: "February 2021 - March 2022",
    url: "https://www.bestinvest.co.uk/",
    img: "https://media-exp2.licdn.com/dms/image/C4E0BAQH0yVgHV65B7Q/company-logo_200_200/0/1655203945123?e=2147483647&v=beta&t=Z_FLOeT2bRTpzz4s1MOyRjI6O_9p5aFva_HhwWXTt-U",
    tech: [
      "TypeScript",
      "React",
      "Redux",
      "Jest",
      "Cypress",
      "Material UI",
      "Node",
      "GitHub",
      "Figma",
      "D3.js",
    ],
  },
  {
    title: "Frontend Engineer (Consultant)",
    companyName: "NatWest via Capco",
    description:
      "<ul><li>Implementated UI enhancements for international payments features for Bankline UI, NatWest’s online banking platform using JavaScript, React, Redux, React Testing Library.</li><li>Collaborated with Technical Analysts, UX designers and Product Managers and prototyped UI improvements</li></ul>",
    date: "February 2021 - March 2022",
    url: "https://www.natwest.com/",
    img: "https://creativereview.imgix.net/content/uploads/2016/10/NW_logo_still_800px.jpg",
    tech: [
      "TypeScript",
      "React",
      "Redux",
      "Jest",
      "Node",
      "SASS",
      "TeamCity",
      "Bitbucket",
    ],
  },
  {
    title: "Frontend Engineer",
    companyName: "Capco",
    description:
      "<ul><li>Project lead and implemented responsive, mobile friendly UI for <a style='display:inline-block' href='https://framework.capco.io/'>Digital Engineering Framework Application</a> using TypeScript, React, automated test execution and deployment to Cloud Run via GitHub Actions.</li><li>Lead the development of the Capco Challenge Application using JavaScript, React, Node.js</li><li>Developed and shipped to production <a style='display:inline-block' href='https://radar.capco.io/'>Capco Tech Radar</a> data visualization in TypeScript, React, D3.js, deployed to GCP via CI/CD pipeline.</li><li>Created unit tests in Jest, integration tests in Cypress ensuring coverage above 80%</li></ul>",
    date: "February 2021 - March 2022",
    url: "https://www.capco.com/",
    img: "https://media.licdn.com/dms/image/v2/D4D0BAQHtQz9E-d-2-A/company-logo_200_200/company-logo_200_200/0/1720715265383/capco_logo?e=1739404800&v=beta&t=KQSnfqFTFs28G8-A59Ld4MSn-sQ1lKX2U3iF0OtbGBg",
    tech: [
      "TypeScript",
      "React",
      "Redux",
      "Jest",
      "Node",
      "styled-components",
      "Cypress",
      "GitHub",
      "Figma",
    ],
  },
  {
    title: "Associate Software Engineer",
    companyName: "Cancer Research UK",
    description:
      "<ul><li>Implemented sign up forms in React for fundraising events</li><li>Wrote unit tests and automated E2E tests</li><li>Accessibility testing to ensure performance</li></ul>",
    date: "May 2020 – February 2021",
    url: "https://www.cancerresearchuk.org/",
    img: "https://www.oasisacademywintringham.org/uploaded/Wintringham/News_and_Events/Letters_Home/2020-2021/CR.png",
    tech: ["TypeScript", "React", "Jest", "styled-components", "AWS", "GitHub"],
  },
  {
    title: "Automation Test Engineer",
    companyName: "Cancer Research UK",
    description:
      "<ul><li>Wrote automated tests in TypeScript, JavaScript and Java</li><li>Contributed to PoC work and assisted with framework migration</li><li>Upskilled a team of 24 testers by pair programming session</li><li>Reported defects, recorded test results and prepared release documents</li></ul>",
    date: "April 2019 – April 2020",
    url: "https://www.cancerresearchuk.org/",
    img: "https://www.oasisacademywintringham.org/uploaded/Wintringham/News_and_Events/Letters_Home/2020-2021/CR.png",
    tech: ["TypeScript", "Cypress", "Java"],
  },
  {
    title: "Quality Assurance Engineer",
    companyName: "CENTURY Tech",
    url: "https://www.century.tech/",
    description:
      "<ul><li>Created automated tests for an online learning platform in Python and TypeScript</li> </ul>",
    date: "March 2018 – March 2019",
    img: "https://pbs.twimg.com/profile_images/1206563816663846913/27e0cG6n_400x400.jpg",
    tech: ["TypeScript", "Python"],
  },
];

export const educationData = [
  {
    id: "birkbeck",
    title: "MSc Statistics",
    companyName: "Birkbeck, University of London",
    description:
      "<ul><li>Probability and Stochastic Modeling</li><li>Financial Data Science in Python</li><li>Machine Learning</li><li>Bayesian Inference</li><li>Statistical Analysis with R</li><li>Thesis: Comparative Analysis of Classical Time Series Models, Hybrid Supervised Learning Models and Long Short-Term Memory Neural Networks in the context of equity price prediction and portfolio optimisation.</li></ul>",
    date: "2021 - 2023",
    url: "https://www.bbk.ac.uk/courses/postgraduate/applied-statistics",
    img: "https://www.bbk.ac.uk/#birkbeck-logo",
  },
  {
    title: "BSc Mathematics with Mathematical Physics",
    companyName: "UCL",
    description:
      "<ul><li>Linear Algebra</li><li>Differential and Multivariate Calculus</li><li>Scientific Computing in Java</li><li>Nuclear and Particle Physics</li><li>Quantum Mechanics</li></ul>",
    date: "2014 – 2018",
    url: "https://www.ucl.ac.uk/prospective-students/undergraduate/degrees/mathematics-mathematical-physics-bsc",
    img: "https://iafor.org/wp-content/uploads/2017/06/UCL-Logo-1.jpg",
  },
];
