import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TechStack from "./TechStack";
import ProjectsPage from "./ProjectsPage";
import ExperiencePage from "./ExperiencePage";
import PageWrapper from "./PageWrapper";
import Footer from "./Footer";
import Menu from "./Menu";
import { ThemeProvider } from "styled-components";
import {
  creativeData,
  certificationsData,
  experienceData,
  educationData,
} from "./data/content";
import { RadarContextProvider } from "./RadarContextProvider";
import {
  main,
  experience,
  education,
  creative,
} from "./Themes";

const App = () => {
  //const [theme, setTheme] = useState<any>(main);
  return (
    <Router>
      <RadarContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <ThemeProvider theme={main}>
                <PageWrapper>
                  <Menu />
                  <TechStack />
                </PageWrapper>
              </ThemeProvider>
            }
          />
          <Route
            path="/creative"
            element={
              <ThemeProvider theme={creative}>
                <PageWrapper>
                  <Menu />
                  <ProjectsPage projects={creativeData} title={"Creative"} />
                </PageWrapper>
              </ThemeProvider>
            }
          />
          <Route
            path="/certifications"
            element={
              <ThemeProvider theme={experience}>
                <PageWrapper>
                  <Menu />
                  <ExperiencePage
                    title={"Experience"}
                    jobs={certificationsData}
                  />
                </PageWrapper>
              </ThemeProvider>
            }
          />
          <Route
            path="/experience"
            element={
              <ThemeProvider theme={experience}>
                <PageWrapper>
                  <Menu />
                  <ExperiencePage title={"Experience"} jobs={experienceData} />
                </PageWrapper>
              </ThemeProvider>
            }
          />
          <Route
            path="/education"
            element={
              <ThemeProvider theme={education}>
                <PageWrapper>
                  <Menu />
                  <ExperiencePage title={"Education"} jobs={educationData} />
                </PageWrapper>
              </ThemeProvider>
            }
          />
        </Routes>
        <Footer />
      </RadarContextProvider>
    </Router>
  );
};

export default App;
